<template>
  <div style="width: 100%">
    <!-- Dialog Fornitura -->
    <!-- Ragione Sociale Search Dialog -->
    <v-dialog persistent v-model="dialogFrontiura" width="700">
      <v-overlay :value="overlay" style="z-index: 10">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-toolbar class="indigo darken-1 headline" primary-title>
          <v-toolbar-title class="white--text">Risultato della ricerca</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="dialogFrontiura = false" small>
                <v-icon v-on="on" v-bind="attrs" depressed color="error"
                  >mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Cancellare </span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text style="max-height: 600px; overflow-y: auto">
          <v-data-table :loading="false" :headers="headersFornitura" :items="searchRes">
            <v-progress-linear
              v-show="progressBar"
              slot="progress"
              color="blue"
              indeterminate
            ></v-progress-linear>
            <template v-slot:[`item.actions`]="props">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" @click="onSetItem(props.item)" small>
                    <v-icon class="mx-2" dark color="#009900"> mdi-check </v-icon>
                  </v-btn>
                </template>
                <span> imposta Fornitura </span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- V-dialog to show Articolo search Result -->
    <v-dialog persistent v-model="dialog_gestione_articoli" width="800">
      <v-card>
        <v-toolbar dark color="indigo">
          <v-toolbar-title>Articoli</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn small light @click="dialog_gestione_articoli = false">
            <v-icon color="red"> mdi-close </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text style="max-height: 500px; overflow-y: auto">
          <v-data-table :headers="ArticoliHeaders" :items="ArticoliLista">
            <template v-slot:[`item.details`]="{ item }">
              <v-col md="4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      style="float: left"
                      @click="selectArticolo(item)"
                      small
                    >
                      <v-icon color="primary">mdi-check</v-icon>
                    </v-btn>
                  </template>
                  <span>Aggiungi</span>
                </v-tooltip>
              </v-col>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- In order to all the Fasi of searched Articolo-->
    <v-dialog persistent v-model="fasi_list_dialog" width="unset">
      <v-card>
        <v-toolbar color="indigo" dense rounded-0>
          <v-toolbar-title class="white--text">Fasi Lista</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn small @click="fasi_list_dialog = false"
            ><v-icon color="error">mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-data-table :headers="Fasiheaders" :items="FasiList">
            <template v-slot:[`item.details`]="{ item }">
              <v-col md="4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      style="float: left"
                      @click="selectCiclo(item)"
                      small
                    >
                      <v-icon color="primary">mdi-check</v-icon>
                    </v-btn>
                  </template>
                  <span>Aggiungi</span>
                </v-tooltip>
              </v-col>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Generale Form -->
    <v-row style="margin-left: 12px; margin-right: 12px; margin-top: 5px">
      <v-hover>
        <template v-slot="{ hover }">
          <v-card
            :elevation="hover ? 24 : 2"
            class="rounded-sm"
            style="width: 100%; max-height: 380px"
          >
            <v-toolbar color="indigo" dense rounded-0>
              <v-toolbar-title class="white--text">GENERALE</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="4" class="pb-0 pt-0">
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="data_ciclo_autocontrollo_format"
                          label="Data"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="data_ciclo_autocontrollo"
                        @input="menu2 = false"
                        locale="it"
                        first-day-of-week="1"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="7" class="pb-0 pt-0">
                    <TextField
                      idForm="QUALITA_COMMON_HEADER"
                      idOggetto="QH_FORNITORE_SEARCH"
                      label_input="Fornitore"
                      palceholder_input="Fornitore"
                      :vmodel="fornitore_search"
                      v-on:changeText="(val) => (this.fornitore_search = val ? val : '')"
                      v-on:keyEnter="searchAnagrafica"
                    />
                  </v-col>
                  <v-col cols="1" class="pb-0 mt-2">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="searchAnagrafica"
                          small
                          v-bind="attrs"
                          v-on="on"
                          style="right: 20px"
                        >
                          <v-icon color="primary">mdi-magnify</v-icon>
                        </v-btn>
                      </template>
                      <span>Cerca Fornitore</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" class="pb-0 pt-4">
                    <v-text-field
                      label="Articolo"
                      dense
                      v-model="cod_articolo"
                      placeholder="Articolo"
                      v-on:keydown.enter.prevent="searchArticolo"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1" class="pb-0 pt-4">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="searchArticolo"
                          small
                          v-bind="attrs"
                          v-on="on"
                          style="right: 20px"
                        >
                          <v-icon color="primary">mdi-magnify</v-icon>
                        </v-btn>
                      </template>
                      <span>Cerca con cod. articolo</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="5" class="pb-0 pt-0">
                    <v-text-field
                      label="Descrizione"
                      v-model="descrizione"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" class="pb-0 pt-0">
                    <v-autocomplete
                      v-model="id_criticita"
                      :items="criticita"
                      item-text="des"
                      item-value="id_tabella"
                      placeholder="Criticità"
                      label="Criticità"
                    >
                      <template #label>
                        <span class="">Criticità</span>
                        <a @click.stop style="pointer-events: all">
                          <Modules
                            v-on:dialogModules="getTabelleAutocontrollo"
                            tipo="CRITICITA"
                            nome="Criticità"
                            setaction="settabelle"
                            getaction="gettabelle"
                            controller="Magazzino"
                            filter="validita = 1"
                            :dati="[{ des: '' }]"
                          />
                        </a>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="2" class="pb-0 pt-0">
                    <TextField
                      idForm="QUALITA_COMMON_HEADER"
                      idOggetto="QH_ARTICOLO_ESTERNA"
                      label_input="Cod. Art. Est."
                      palceholder_input="Cod. Art. Est."
                      :vmodel="cod_articolo_esterno"
                      v-on:changeText="(val) => (this.cod_articolo_esterno = val)"
                    />
                  </v-col>
                  <v-col cols="2" class="pb-0 pt-0">
                    <TextField
                      idForm="QUALITA_COMMON_HEADER"
                      idOggetto="QH_INDICE_MODIFICA"
                      label_input="Indice"
                      palceholder_input="Indice"
                      :vmodel="indice_modifica"
                      v-on:changeText="(val) => (this.indice_modifica = val)"
                    />
                  </v-col>
                  <v-col cols="2" class="pb-0 pt-0">
                    <v-menu
                      v-model="menu3"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="dum_format"
                          label="DUM"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dum"
                        @input="menu3 = false"
                        locale="it"
                        first-day-of-week="1"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="4" class="pb-0 pt-0">
                    <v-autocomplete
                      label="Macchina"
                      :items="macchinaList"
                      item-text="des_macchina"
                      item-value="id_macchina"
                      v-model="id_macchina"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" class="pb-0 pt-0">
                    <TextField
                      idForm="QUALITA_COMMON_HEADER"
                      idOggetto="QH_FASE_ATTUALE"
                      label_input="Fase ciclo attuale"
                      palceholder_input="Fase ciclo attuale"
                      :vmodel="fase_ciclo_attuale_generale"
                      v-on:changeText="(val) => (this.fase_ciclo_attuale_generale = val)"
                    />
                  </v-col>
                  <v-col cols="1" class="pb-0 mt-2">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :disabled="disabled"
                          @click="getCicli"
                          small
                          v-bind="attrs"
                          v-on="on"
                          style="right: 20px"
                        >
                          <v-icon color="primary">mdi-magnify</v-icon>
                        </v-btn>
                      </template>
                      <span>Cerca con Fase Code</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="5" class="pb-0 pt-0">
                    <TextField
                      idForm="QUALITA_COMMON_HEADER"
                      idOggetto="QH_FASE_SUCCESSIVA"
                      label_input="Fase ciclo successiva"
                      palceholder_input="Fase ciclo successiva"
                      :vmodel="fase_ciclo_successiva_generale"
                      v-on:changeText="
                        (val) => (this.fase_ciclo_successiva_generale = val)
                      "
                    />
                  </v-col>
                  <!--
                  <v-col :v-if="show_approvato_generale" cols="2" class="pb-0 pt-0">
                    <v-checkbox
                      label="Approvato"
                      v-model="nominativo_firmatario"
                      :false-value="0"
                      :true-value="1"
                    ></v-checkbox>
                  </v-col> -->
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </template>
      </v-hover>
    </v-row>
    <!-- V-snackbar to show result -->
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{ snackbar_icon }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>
      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="margin-top: -6rem; float: right !important; margin-right: -1rem !important"
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import moment from "moment";
import TextField from "@/components/TOOLS/FormComponentPermissions/TextField";
export default {
  components: {
    // Importing module for V-autocomplete
    Modules: () => import("@/components/TOOLS/Modules"),
    TextField,
  },
  props: {
    is_Approved: {
      type: Boolean,
    },
    testaCommune: {
      type: Object,
    },
  },
  computed: {
    dum_format() {
      return this.dum ? moment(this.dum).format("DD/MM/YYYY") : "";
    },
    data_ciclo_autocontrollo_format() {
      return this.data_ciclo_autocontrollo
        ? moment(this.data_ciclo_autocontrollo).format("DD/MM/YYYY")
        : "";
    },
    show_approvato_generale: function () {
      return this.is_Approved;
    },
    disabled() {
      return this.disableFasiSearch;
    },
  },
  data() {
    return {
      //Main Icon for SnackBar
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
      disableFasiSearch: true,
      // Variables nee
      // Fasi Lista
      Fasiheaders: [
        {
          text: "",
          value: "details",
          sortable: false,
        },
        {
          text: "Cod Fase",
          value: "cod_fase",
          sortable: false,
        },
        {
          text: "Descrizione Fase",
          value: "des",
          sortable: false,
        },
      ],
      FasiList: [],
      // Articoli Header
      ArticoliHeaders: [
        {
          text: "",
          value: "details",
          sortable: false,
        },
        {
          text: "Cod Articolo",
          value: "cod_articolo",
          sortable: false,
        },
        {
          text: "Des Articolo",
          value: "des_articolo",
          sortable: false,
        },
        {
          text: "Famiglia",
          value: "id_famiglia",
          sortable: false,
        },
        {
          text: "Tipo",
          value: "id_tipo",
          sortable: false,
        },
        {
          text: "Tipo Fornitura",
          value: "id_tipo_fornitura",
          sortable: false,
        },
      ],
      ArticoliLista: [],
      // Var to toggle Approved Check box
      // show_approvato_generale: this.is_Approved,
      fornitore_search:
        this.testaCommune != undefined ? this.testaCommune.ragione_sociale : "",
      progressBar: false,
      overlay: false,
      dialog_gestione_articoli: false,
      dialogFrontiura: false,
      data_ciclo_autocontrollo:
        this.testaCommune != undefined ? this.testaCommune.data_ciclo_autocontrollo : "",
      descrizione: this.testaCommune != undefined ? this.testaCommune.descrizione : "",
      id_articolo: "",
      cod_articolo_esterno:
        this.testaCommune != undefined ? this.testaCommune.cod_articolo_esterno : "",
      cod_articolo: this.testaCommune != undefined ? this.testaCommune.cod_articolo : "",
      des_articolo: "",
      indice_modifica:
        this.testaCommune != undefined ? this.testaCommune.indice_modifica : "",
      dum: this.testaCommune != undefined ? this.testaCommune.dum : "",
      id_macchina: this.testaCommune != undefined ? this.testaCommune.id_macchina : "",
      // id_ciclo_attuale: '',
      // id_ciclo_successivo: '',
      id_fase_attuale:
        this.testaCommune != undefined ? this.testaCommune.id_fase_attuale : "",
      id_fase_successivo:
        this.testaCommune != undefined ? this.testaCommune.id_fase_successivo : "",
      id_criticita: this.testaCommune != undefined ? this.testaCommune.id_criticita : "",
      id_firmatario: "",
      nominativo_firmatario:
        this.testaCommune != undefined
          ? parseInt(this.testaCommune.nominativo_firmatario)
          : false,
      id_fmea: "",
      num_fmea: "",
      rev_fmea: "",
      id_fmea_fasi: "",
      id_fmea_fase_collaudo: "",
      num_revisione: "",
      id_fornitore:
        this.testaCommune != undefined ? parseInt(this.testaCommune.id_fornitore) : 0,
      codice_fornitore: "",
      ragione_sociale:
        this.testaCommune != undefined ? parseInt(this.testaCommune.ragione_sociale) : "",
      note_interne: "",
      annotazione: "",
      validita: this.testaCommune != undefined ? this.testaCommune.validita : 1,
      date_ins: "",
      date_mod: "",
      id_utente: "",
      fasi_list_dialog: false,
      approvato_generale: 0,
      menu2: false,
      menu3: false,
      moduloAttuale: "",
      numero_info: this.testaCommune.numero_info,
      rev_info: this.testaCommune.rev_info,
      fmea_info: this.testaCommune.fmea_info,
      fmea_rev: this.testaCommune.fmea_rev,
      data_generale: this.testaCommune.data_generale,
      descrizione_generale: this.testaCommune.descrizione_generale,
      codice_cod_articolo: this.testaCommune.codice_cod_articolo,
      indice_generale: this.testaCommune.indice_generale,
      dum_generale: this.testaCommune.dum_generale,
      fase_ciclo_attuale_generale: this.testaCommune.fase_ciclo_attuale_generale,
      fase_ciclo_successiva_generale: this.testaCommune.fase_ciclo_successiva_generale,
      des_fase_ciclo_successiva_generale: this.testaCommune
        .des_fase_ciclo_successiva_generale,
      criticita: [],
      macchinaList: [],
      searchRes: [],
      headersFornitura: [
        {
          align: "start",
          text: "",
          value: "actions",
          sortable: false,
        },
        {
          text: "Tipo",
          value: "_tipo",
          sortable: false,
        },
        {
          text: "Codice",
          value: "codice",
          sortable: false,
        },
        {
          text: "Ragione sociale",
          value: "ragione_sociale",
          sortable: false,
        },
        {
          text: "Indrizzo",
          value: "indirizzo",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    onSetItem(item) {
      this.fornitore_search = item.ragione_sociale;
      this.id_fornitore = item.id_anagrafica;
      this.dialogFrontiura = false;
    },
    //   IN order to search fornitura
    searchAnagrafica() {
      let searchReq = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getragionesociale",
          cod_anagrafica: this.fornitore_search,
          des_anagrafica: "",
          tipo_anagrafica: "F",
          token: localStorage.getItem("user_token"),
        },
      };
      this.overlay = true;
      this.$store.dispatch("api", searchReq).then((res) => {
        this.dialogFrontiura = true;
        this.overlay = false;
        this.searchRes = res.data;
        if (this.searchRes.length === 0) {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "Ragione Sociale non è stato trovato. Riprova !";
          this.snackbar_text_color = "white";
          this.progressBar = false;
        }
        if (this.searchRes.length > 0) {
          this.progressBar = true;
        }
      });
      this.client = false;
    },
    // Getting list to populate v-autocomplete of criticita
    getTabelleAutocontrollo() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.getItem("user_token"),
          tipo: "CRITICITA",
          filter: "validita = 1",
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.criticita = res.data;
      });
    },
    // selecting the article from main search dialog
    selectArticolo(item) {
      this.cod_articolo = item.cod_articolo;
      this.id_articolo = item.id_articolo;
      this.dialog_gestione_articoli = false;
      this.disableFasiSearch = false;
    },
    // In order to Select the Current Fasi and next Fasi for selected Articolo
    selectCiclo(it) {
      let index = this.FasiList.indexOf(it);
      var nextItem = "";
      this.fase_ciclo_attuale_generale = it.des;
      this.id_fase_attuale = it.id_fase;
      if (index >= 0 && index < this.FasiList.length - 1)
        nextItem = this.FasiList[index + 1];
      if (nextItem) {
        this.fase_ciclo_successiva_generale = nextItem.des;
        this.id_fase_successivo = nextItem.id_fase;
      } else {
        this.fase_ciclo_successiva_generale = "";
        this.id_fase_successivo = "";
      }
      this.fasi_list_dialog = false;
    },
    // In order to Search Articolo in Detail header
    searchArticolo() {
      let search_Articolo = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoli",
          token: localStorage.getItem("user_token"),
          cod_articolo: this.cod_articolo,
        },
      };
      // Eseguo la chiamata al BE
      this.$store.dispatch("api", search_Articolo).then((res) => {
        if (res.data.lista.length === 0) {
          this.snackbar = true;
          this.snackbar_background = "error";
          this.snackbar_icon = "mdi-close";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "L'articolo non è stato trovato. Riprova !";
          this.snackbar_text_color = "white";
          this.loadingVal = false;
          this.progressBar = false;
          this.savebutton = false;
          this.topDiv = true;
        }
        if (res.data.lista.length > 1) {
          this.dialog_gestione_articoli = true;
          this.ArticoliLista = res.data.lista;
        }
        if (res.data.lista.length == 1) {
          this.id_articolo = res.data.lista[0].id_articolo;
          this.cod_articolo = res.data.lista[0].cod_articolo;
          this.disableFasiSearch = false;
          // Getting Ciclo lista to populate the table
        }
      });
    },
    // Call to get all ciclo fasi for selected articolo
    getCicli() {
      let getCicolo = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoliciclo",
          token: localStorage.getItem("user_token"),
          id_articolo: this.id_articolo,
        },
      };
      this.$store.dispatch("api", getCicolo).then((res) => {
        this.FasiList = res.data;
        this.fasi_list_dialog = true;
      });
    },
    changed() {
      this.$emit("update", this.testaCommune);
    },
  },
  // Looking For the changes in all variable and sending back to parent component
  watch: {
    numero_info: {
      immediate: true,
      handler(val, oldVal) {
        if (val != oldVal) {
          this.$emit("update", val);
        }
      },
    },
    rev_info: {
      immediate: true,
      handler(val, oldVal) {
        if (val != oldVal) {
          this.$emit("revinfo", val);
        }
      },
    },
    fmea_info: {
      immediate: true,
      handler(val, oldVal) {
        if (val != oldVal) {
          this.$emit("fmeainfo", val);
        }
      },
    },
    fmea_rev: {
      immediate: true,
      handler(val, oldVal) {
        if (val != oldVal) {
          this.$emit("fmearev", val);
        }
      },
    },
    data_ciclo_autocontrollo: {
      immediate: true,
      handler(val, oldVal) {
        if (val != oldVal) {
          this.$emit("datagenerale", val);
        }
      },
    },
    cod_articolo: {
      immediate: true,
      handler(val, oldVal) {
        if (val != oldVal) {
          this.$emit("articologenerale", val);
        }
      },
    },
    id_articolo: {
      immediate: true,
      handler(val, oldVal) {
        if (val != oldVal) {
          this.$emit("idarticolo", val);
        }
      },
    },
    descrizione: {
      immediate: true,
      handler(val, oldVal) {
        if (val != oldVal) {
          this.$emit("desgenerale", val);
        }
      },
    },
    cod_articolo_esterno: {
      immediate: true,
      handler(val, oldVal) {
        if (val != oldVal) {
          this.$emit("codartgen", val);
        }
      },
    },
    indice_modifica: {
      immediate: true,
      handler(val, oldVal) {
        if (val != oldVal) {
          this.$emit("indicegenerale", val);
        }
      },
    },
    dum: {
      immediate: true,
      handler(val, oldVal) {
        if (val != oldVal) {
          this.$emit("dumgen", val);
        }
      },
    },
    id_criticita: {
      immediate: true,
      handler(val, oldVal) {
        if (val != oldVal) {
          this.$emit("criticagen", val);
        }
      },
    },
    fase_ciclo_attuale_generale: {
      immediate: true,
      handler(val, oldVal) {
        if (val != oldVal) {
          this.$emit("fasiattgen", this.id_fase_attuale);
        }
        if (val != oldVal) {
          this.$emit("des_fase_attuale", this.fase_ciclo_attuale_generale);
        }
      },
    },
    fase_ciclo_successiva_generale: {
      immediate: true,
      handler(val, oldVal) {
        if (val != oldVal) {
          this.$emit("fasesucc", this.id_fase_successivo);
        }
        if (val != oldVal) {
          this.$emit("fasiattsucc", this.fase_ciclo_successiva_generale);
        }
      },
    },
    des_fase_ciclo_successiva_generale: {
      immediate: true,
      handler(val, oldVal) {
        if (val != oldVal) {
          this.$emit("revupdate", val);
        }
      },
    },
    id_macchina: {
      immediate: true,
      handler(val, oldVal) {
        if (val != oldVal) {
          this.$emit("macchinagen", val);
        }
      },
    },
    menu2: {
      immediate: true,
      handler(val, oldVal) {
        if (val != oldVal) {
          this.$emit("menu2", val);
        }
      },
    },
    nominativo_firmatario: {
      immediate: true,
      handler(val, oldVal) {
        if (val != oldVal) {
          this.$emit("approvatogen", val);
        }
      },
    },
    fornitore_search: {
      immediate: true,
      handler(val, oldVal) {
        if (val != oldVal) {
          this.$emit("sendragione", val);
          this.$emit("sendidFronitore", this.id_fornitore);
        }
      },
    },
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        this.dialogFrontiura = false;
        this.dialog_gestione_articoli = false;
        this.fasi_list_dialog = false;
      }
    });
    this.getTabelleAutocontrollo();
    this.cod_articolo = this.testaCommune.cod_articolo;
    if (this.testaCommune.id_benestare && this.testaCommune.id_benestare > -1) {
      this.data_ciclo_autocontrollo = this.testaCommune.data_benestare;
    }
    if (
      this.testaCommune.id_piano_controllo &&
      this.testaCommune.id_piano_controllo > -1
    ) {
      this.data_ciclo_autocontrollo = this.testaCommune.data_piano;
      this.descrizione = this.testaCommune.des_piano_controllo;
    }
    this.fase_ciclo_attuale_generale = this.testaCommune.des_fase_attuale;
    this.fase_ciclo_successiva_generale = this.testaCommune.des_fase_successivo;
    // Getting list of macchines
    let manu_detail = {
      controller: "Manutenzione",
      method: "POST",
      richiesta: {
        action: "getlistamacchine",
        token: localStorage.getItem("user_token"),
      },
    };
    this.$store.dispatch("api", manu_detail).then((res) => {
      this.macchinaList = res.data;
    });
    let routeParams = this.$route.params;
    if (routeParams.foo == -1) {
      this.numero_info = "";
      this.rev_info = "";
      this.fmea_info = "";
      this.fmea_rev = "";
      this.data_generale = "";
      this.cod_articolo = "";
      this.descrizione_generale = "";
      this.codice_cod_articolo = "";
      this.indice_generale = "";
      this.dum_generale = "";
      this.id_criticita = "";
      this.fase_ciclo_attuale_generale = "";
      this.fase_ciclo_successiva_generale = "";
      this.des_fase_ciclo_successiva_generale = "";
    }
    this.moduloAttuale = localStorage.getItem("modulo");
  },
};
</script>
